import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import TheLoader from "../../components/TheLoader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopNavGuest from "../../components/navbar/TopNavGuest";
import CapacitorController from "../../components/CapacitorController";
import GuestNav from "../../components/navbar/GuestNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iOS from "../../composable/ios";

var session_url = `${process.env.REACT_APP_API_URL}password/email`;

const ForgotPassword = () => {
  // Variable
  const [formData, setFormData] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [FormError, setError] = useState({ status: null });
  const { t } = useTranslation();
  let navigate = useNavigate();

  // Functions
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.email !== "") {
      setLoading(true);

      axios
        .post(session_url, {
          email: formData.email,
        })
        .then(function(response) {
          setTimeout(() => {
            navigate("./success", {
              state: { message: response.data.message },
            });
            setLoading(false);
          }, 4000);
        })
        .catch(function(error) {
          setError(error.response);
          setLoading(false);
        });
    }
  };

  return (
    <div className={`grid grid-cols-12 ${iOS() ? "mt-6" : "mt-12"}`}>
      <TheLoader isLoading={loading} />
      <TopNavGuest Title={t("general.login")} />
      <div className="content default-cols container px-4">
        <div className="grid grid-cols-1 gap-y-4">
          <h3 className="col-span-full mt-20 font-robotoslab font-bold text-primary dark:text-primary-light">
            {t("general.forgot-password")}
          </h3>
          <form onSubmit={handleSubmit}>
            <p className="pb-3 text-sm">{t("general.email-address")}</p>

            <label htmlFor="email">
              <input
                type="email"
                id="email"
                placeholder={t("general.email-address")}
                className={`dark:glass h-10 w-full rounded-full bg-white px-4 py-2 text-sm shadow-md ${FormError.status === 404 && "border-makita-red text-makita-red"}`}
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
            </label>
            {FormError.status === 404 && <p className="mb-3 mt-[-0.5rem] text-makita-red">{FormError.data.message}</p>}

            <div className="mt-6 pb-2 flex flex-col gap-x-4 gap-y-6 lg:flex-row lg:justify-between">
              <div className="w-full">
                <button
                  className="btn btn--primary w-full disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-primary"
                >
                  {t("general.reset-password")}
                  <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
                </button>
              </div>

              <Link to="../" className="flex w-full items-center justify-end">
                <p className="animate font-robotoslab text-primary hover:opacity-50 dark:text-primary-light">
                  {t("general.proceed-as-visitor")}
                </p>
              </Link>
            </div>
          </form>
        </div>
      </div>

      <GuestNav />
      <CapacitorController />
    </div>
  );
};

export default ForgotPassword;
