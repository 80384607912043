/* eslint-disable no-unused-vars */
import React from "react";
import TopNavGuest from "../../components/navbar/TopNavGuest";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { Trans, useTranslation } from "react-i18next";
import CapacitorController from "../../components/CapacitorController";
import iOS from "../../composable/ios";
import ErrorMessage from "../../components/ErrorMessage";
import Autocomplete from "react-google-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GuestNav from "../../components/navbar/GuestNav";
import TheLoader from "../../components/TheLoader";
import CustomSelect from "../../components/CustomSelect";

const Register = () => {
  const [formType, setFormType] = useState("");
  const [formData, setFormData] = useState("");
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState("");
  const [countries, setCountries] = useState("");
  const [registered, setRegistered] = useState("");
  const [formSection, setFormSection] = useState(1);
  const [formError, setFormError] = useState([]);
  const [addressView, setAddressView] = useState(false);
  let navigate = useNavigate();
  const [searchCountry, setSearchCountry] = useState(["nl"]);
  const [passwordType, setPasswordType] = useState("password");
  const { t } = useTranslation();
  const accountTypes = [
    { value: "professional", label: t("general.business") },
    { value: "private", label: t("general.personal") },
  ];
  const companyTypes = [
    { value: "bv", label: t("general.bv") },
    { value: "vof", label: t("general.vof") },
    { value: "zzp", label: t("general.zzp") },
    { value: "club", label: t("general.club") },
  ];
  const genders = [
    { value: "M", label: t("general.male") },
    { value: "F", label: t("general.female") },
    { value: "X", label: t("general.other") },
  ];

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("userAuth")}`,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
      "content-type": "application/json",
    },
  };

  // Handle postalAPi
  useEffect(() => {
    if (formData.housenumber === undefined || formData.housenumber.length < 1) return;
    if (formData.zipcode === undefined || formData.zipcode.length < 6) return;

    let item = {
      zipcode: formData.zipcode,
      housenumber: formData.housenumber,
    };

    axios.post(`${process.env.REACT_APP_API_URL}zipcode`, item).then((res) => {
      setFormData({ ...formData, city: res.data.city, street: res.data.street });
    });
  }, []);

  // Country handler
  const handleCountry = (selectedCountry) => {
    setFormData({ ...formData, country: selectedCountry });
    if (countries) {
      let searchCountry = countries.filter((countryData) => countryData.id === selectedCountry);
      setSearchCountry([`${searchCountry[0].code}`]);
    }
  };

  const handleSetAddress = (item) => {
    let location = item.address_components;
    let zipcode = location.filter((item) => item.types.includes("postal_code"))[0].short_name;
    let street = location.filter((item) => item.types.includes("route"))[0].short_name;
    let housenumber = location.filter((item) => item.types.includes("street_number"))[0].short_name;
    let city = location.filter((item) => item.types.includes("locality"))[0].short_name;

    setFormData({
      ...formData,
      zipcode: zipcode,
      street: street,
      housenumber: housenumber,
      city: city,
    });
    setAddressView(true);
  };

  const handleSection2 = (e) => {
    e.preventDefault();

    let sectionData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      gender: formData.gender,
      phonenumber: formData.phonenumber,
      company_name: formData.company_name,
      company_type: formData.legalForm,
      btw: formData.btw,
      kvk: formData.kvk,
      branche_id: formData.branche_id,
      type: formType,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}register/profile`, sectionData, axiosConfig)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("register", JSON.stringify(formData));
          setFormSection(3);
        }
      })
      .catch(function (error) {
        setFormError(error.response.data.errors);
      });
  };

  const handleSection3 = (e) => {
    e.preventDefault();

    let sectionData = {
      country_id: countries.filter((countryData) => countryData.code === searchCountry[0])[0].id,
      street: formData.street,
      housenumber: formData.housenumber,
      city: formData.city,
      postal: formData.zipcode,
    };
    if (formData.addition !== undefined) {
      sectionData.addition = formData.addition;
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}register/address`, sectionData, axiosConfig)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("register", JSON.stringify(formData));
          setFormSection(4);
        }
      })
      .catch(function (error) {
        setFormError(error.response.data.errors);
      });
  };

  const handleSection4 = (e) => {
    e.preventDefault();

    let formDataObject = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      branche_id: formData.branche_id,
      type: formType,
      phonenumber: formData.phonenumber,
      gender: formData.gender,
      street: formData.street,
      city: formData.city,
      postal: formData.zipcode,
      housenumber: formData.housenumber,
      addition: formData.addition,
      country_id: countries.filter((countryData) => countryData.code === searchCountry[0])[0].id,
      email: formData.email,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
      privacy_statement: formData.privacy_statement,
    };

    if (formData.type === "professional") {
      formDataObject = {
        ...formDataObject,
        company_name: formData.company_name,
        company_type: formData.legalForm,
        btw: formData.btw,
        kvk: formData.kvk,
        branche_id: formData.branche_id,
      };
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}register/account`, formDataObject, axiosConfig)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem("register", JSON.stringify(formData));

          navigate("/register/confirm");
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          setFormError(error.response.data.errors);
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("register") !== null) {
      let local = localStorage.getItem("register");
      if (local !== null) {
        setFormData(JSON.parse(local));
      }
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}branch`)
      .then((res) => {
        setBranches(res.data.data);
      })
      .catch(function (error) {
        throw new Error(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}country`)
      .then((response) => {
        setCountries(response.data.data);
      })
      .catch(function (error) {
        throw new Error(error);
      });
  }, [formType]);

  return (
    <>
      <div className={`${iOS() ? "mt-[98px]" : "mt-20"} grid grid-cols-12`}>
        <TheLoader isLoading={loading} />

        <TopNavGuest Title={t("general.register")} />

        <div
          className={
            registered
              ? "show fixed z-40 flex h-screen w-screen items-center justify-center bg-white bg-opacity-100"
              : "hidden"
          }
        >
          <div className="opacity-100">
            {registered !== "" && (
              <div className="flex flex-col">
                <div>
                  <h2 className="px-10 text-center text-xl font-bold">{t("general.confirm-your-account")}</h2>
                </div>
                <div className="mt-6 flex justify-center">
                  <Button to="../aanmelden" icon="key" text={t("general.login")} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div id="content" className={`default-cols container px-4 pb-28 ${iOS() && "pt-6"}`}>
          <div className="relative mb-10 flex w-full justify-between">
            <div className="absolute top-1/2 z-0 h-px w-full -translate-y-1/2 bg-primary content-['']" />

            {[2, 3, 4, 5].map((section) => (
              <div
                key={section}
                className={`relative z-[1] flex size-8 items-center justify-between rounded-full ${
                  formSection >= section ? "bg-primary" : "bg-button-inactive dark:bg-button-inactive-dark"
                }`}
              >
                <FontAwesomeIcon icon="fa-regular fa-check" className="w-full text-center text-xl text-white" />
              </div>
            ))}
          </div>

          {formSection === 1 && (
            <>
              <p className="my-6">{t("general.select-account-type")}</p>
              <div className="col grid grid-cols-1 gap-y-6 md:grid-cols-2 md:gap-x-6">
                <div
                  className="col-span-1 flex items-center justify-center rounded-xl bg-primary px-6 py-3 font-bold text-white hover:cursor-pointer"
                  onClick={() => setFormType("private") + setFormSection(2)}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="font-bold">{t("general.personal")}</div>
                    <div className="flex items-center justify-center rounded-full border border-white p-3">
                      <FontAwesomeIcon icon="fa-regular fa-user" className="text-2xl" />
                    </div>
                  </div>
                </div>
                <div
                  className="col-span-1 flex items-center justify-center rounded-xl bg-primary px-6 py-3 font-bold text-white hover:cursor-pointer"
                  onClick={() => setFormType("professional") + setFormSection(2)}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="font-bold">{t("general.business")}</div>
                    <div className="flex items-center justify-center rounded-full border border-white p-3">
                      <FontAwesomeIcon icon="fa-regular fa-user-helmet-safety" className="text-2xl" />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {formSection === 2 && (
            <>
              <form className="flex w-full flex-col gap-y-6 pb-5" onSubmit={handleSection2}>
                <div>
                  <p className="pb-3 text-sm">{t("general.account-type")}</p>
                  <CustomSelect
                    id="accountType"
                    placeholder={t("general.account-type")}
                    options={accountTypes}
                    defaultValue={accountTypes.filter((type) => type.value === formType)}
                    onChange={({ value }) => setFormType(value)}
                  />
                  <ErrorMessage message={formError.type} />
                </div>

                {formType === "professional" && (
                  <>
                    <div>
                      <p className="pb-3 text-sm">{t("general.company-name")}*</p>
                      <input
                        id="company_name"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            company_name: e.target.value,
                          })
                        }
                        placeholder="Bedrijfsnaam"
                        className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                        value={formData.company_name}
                      />
                      <ErrorMessage message={formError.company_name} />
                    </div>
                    <div>
                      <p className="pb-3 text-sm">{t("general.legal-form")}*</p>
                      <CustomSelect
                        id="legalForm"
                        placeholder={t("general.choose-legal-form")}
                        options={companyTypes}
                        defaultValue={companyTypes.filter((type) => type.value === formData.legalForm)}
                        onChange={({ value }) =>
                          setFormData({
                            ...formData,
                            legalForm: value,
                          })
                        }
                      />
                      <ErrorMessage message={formError.legalForm} />
                    </div>
                    <div>
                      <p className="pb-3 text-sm">{t("general.coc")}*</p>
                      <input
                        value={formData.kvk}
                        id="kvk"
                        onChange={(e) => setFormData({ ...formData, kvk: e.target.value })}
                        placeholder={t("general.enter-coc-number")}
                        className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                      />
                      <ErrorMessage message={formError.kvk} />
                    </div>

                    <div>
                      <p className="pb-3 text-sm">{t("general.vat")}*</p>
                      <input
                        value={formData.btw}
                        id="btw"
                        onChange={(e) => setFormData({ ...formData, btw: e.target.value })}
                        placeholder={t("general.enter-vat-number")}
                        className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                      />
                      <ErrorMessage message={formError.btw} />
                    </div>

                    <div>
                      <p className="pb-3 text-sm">{t("general.industry")}</p>
                      <CustomSelect
                        id="branch_id"
                        placeholder={t("general.choose-industry")}
                        options={branches.map((branche) => ({
                          value: branche.id,
                          label: branche.name,
                        }))}
                        defaultValue={branches.filter((branche) => branche.value === formData.branche_id)}
                        onChange={({ value }) =>
                          setFormData({
                            ...formData,
                            branche_id: value,
                          })
                        }
                      />
                      <ErrorMessage message={formError.branche_id} />
                    </div>
                  </>
                )}

                <div>
                  <p className="pb-3 text-sm">{t("general.gender")}*</p>
                  <CustomSelect
                    id="gender"
                    placeholder={t("general.choose-gender")}
                    options={genders}
                    defaultValue={genders.filter((gender) => gender.value === formData.gender)}
                    onChange={({ value }) =>
                      setFormData({
                        ...formData,
                        gender: value,
                      })
                    }
                  />
                  <ErrorMessage message={formError.gender} />
                </div>

                <div className="flex flex-col gap-x-4 gap-y-6 lg:flex-row">
                  <div className="w-full">
                    <p className="pb-3 text-sm">{t("general.first-name")}*</p>
                    <input
                      id="first_name"
                      onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                      placeholder={t("general.first-name")}
                      className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                      value={formData.first_name}
                    />
                    <ErrorMessage message={formError.first_name} />
                  </div>

                  <div className="w-full">
                    <p className="pb-3 text-sm">{t("general.last-name")}*</p>
                    <input
                      id="last_name"
                      onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                      placeholder={t("general.last-name")}
                      className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                      value={formData.last_name}
                    />
                    <ErrorMessage message={formError.last_name} />
                  </div>
                </div>

                <div>
                  <p className="pb-3 text-sm">{t("general.phonenumber")}</p>
                  <input
                    id="phonenumber"
                    type="tel"
                    onChange={(e) => setFormData({ ...formData, phonenumber: e.target.value })}
                    placeholder={t("general.phonenumber")}
                    className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                    value={formData.phonenumber}
                  />
                  <ErrorMessage message={formError.phonenumber} />
                </div>

                <div className="flex w-full justify-between gap-x-4">
                  <button
                    type="button"
                    className="dark:glass flex h-[46px] w-full items-center justify-between gap-4 rounded-xl bg-white px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light lg:w-max"
                    onClick={() => setFormSection(formSection - 1)}
                  >
                    <FontAwesomeIcon icon="fa-regular fa-arrow-left-long" /> Vorige stap
                  </button>
                  <button
                    type="submit"
                    className="flex h-[46px] w-full items-center justify-between gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold shadow-md dark:bg-primary lg:w-max"
                  >
                    Volgende stap <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
                  </button>
                </div>
              </form>
            </>
          )}

          {formSection === 3 && (
            <>
              <form className="w-full gap-3 pb-5" onSubmit={handleSection3}>
                <div>
                  <p className="pb-3 text-sm">{t("general.country")}</p>
                  <CustomSelect
                    id="country"
                    placeholder={t("general.choose-country")}
                    options={countries.map((country) => ({
                      value: country.id,
                      label: country.name,
                    }))}
                    onChange={({ value }) => handleCountry(value)}
                  />
                  <ErrorMessage message={formError.country_id} />
                </div>

                {!addressView ? (
                  <>
                    <p className="mt-6 pb-3 text-sm">{t("general.address")}</p>
                    <Autocomplete
                      // eslint-disable-next-line no-undef
                      apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                      options={{
                        types: ["address"],
                        componentRestrictions: {
                          country: searchCountry,
                        },
                      }}
                      onPlaceSelected={(place) => {
                        handleSetAddress(place);
                      }}
                      language="nl"
                      className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                    />
                    <button
                      onClick={() => setAddressView(true)}
                      type="button"
                      className="mt-6 text-primary dark:text-primary-light"
                    >
                      {t("general.address-not-available")}
                    </button>
                  </>
                ) : (
                  <>
                    <div className="grid grid-cols-1 gap-y-6 pt-6 md:grid-cols-2 md:gap-x-4">
                      <div>
                        <p className="pb-3 text-sm">{t("general.zip-code")}</p>
                        <input
                          id="zipcode"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              zipcode: e.target.value,
                            })
                          }
                          placeholder={t("general.zip-code")}
                          value={formData.zipcode}
                          className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                        />
                        <ErrorMessage message={formError.postal} />
                      </div>
                      <div>
                        <p className="pb-3 text-sm">{t("general.housenumber")}</p>
                        <input
                          id="housenumber"
                          className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              housenumber: e.target.value,
                            })
                          }
                          placeholder={t("general.housenumber")}
                          value={formData.housenumber}
                        />
                        <ErrorMessage message={formError.housenumber} />
                      </div>
                      <div>
                        <p className="pb-3 text-sm">{t("general.addition")}</p>
                        <input
                          id="addition"
                          className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              addition: e.target.value,
                            })
                          }
                          placeholder={t("general.addition")}
                          value={formData.addition}
                        />
                        <ErrorMessage message={formError.addition} />
                      </div>
                      <div>
                        <p className="pb-3 text-sm">{t("general.streetname")}</p>
                        <input
                          id="street"
                          className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                          onChange={(e) => setFormData({ ...formData, street: e.target.value })}
                          placeholder={t("general.streetname")}
                          value={formData.street}
                        />
                        <ErrorMessage message={formError.street} />
                      </div>
                      <div>
                        <p className="pb-3 text-sm">{t("general.residence")}</p>
                        <input
                          id="city"
                          className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                          onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                          placeholder={t("general.residence")}
                          value={formData.city}
                        />
                        <ErrorMessage message={formError.city} />
                      </div>
                    </div>
                  </>
                )}

                <div className="mt-6 flex w-full justify-between gap-x-4">
                  <button
                    type="button"
                    className="dark:glass flex h-[46px] w-full items-center justify-between gap-4 rounded-xl bg-white px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                    onClick={() => setFormSection(formSection - 1)}
                  >
                    <FontAwesomeIcon icon="fa-regular fa-arrow-left-long" /> Vorige stap
                  </button>
                  <button
                    type="submit"
                    className="flex h-[46px] w-full items-center justify-between gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold shadow-md dark:bg-primary"
                  >
                    Volgende stap <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
                  </button>
                </div>
              </form>
            </>
          )}

          {formSection === 4 && (
            <>
              <form className="grid w-full grid-cols-1 gap-y-6 pb-5" onSubmit={handleSection4}>
                <div>
                  <p className="pb-3 text-sm">{t("general.email-address")}</p>
                  <input
                    id="email"
                    type="email"
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    placeholder={t("general.email-address")}
                    className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                  />
                </div>
                {formError.email !== undefined && <ErrorMessage message={formError.email} />}
                <div className="relative">
                  <p className="pb-3 text-sm">{t("general.password")}</p>
                  <input
                    id="password"
                    type={passwordType}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                    placeholder={t("general.password")}
                    className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                  />

                  <div className="absolute bottom-2 right-4">
                    <button type="button" onClick={togglePassword}>
                      <FontAwesomeIcon icon="fa-regular fa-eye" className="text-primary dark:text-primary-light" />
                    </button>
                  </div>
                </div>
                {formError.password !== undefined && <ErrorMessage message={formError.password} />}
                <div className="relative">
                  <p className="pb-3 text-sm">{t("general.password-confirmation")}</p>
                  <input
                    id="password_confirmation"
                    type={passwordType}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        password_confirmation: e.target.value,
                      })
                    }
                    placeholder={t("general.password-confirmation")}
                    className="h-10 w-full rounded-full border-none bg-white px-4 py-2 text-sm shadow-md dark:bg-secondary-dark"
                  />
                  <div className="absolute bottom-2 right-4">
                    <button type="button" onClick={togglePassword}>
                      <FontAwesomeIcon icon="fa-regular fa-eye" className="text-primary dark:text-primary-light" />
                    </button>
                  </div>
                </div>
                {formError.password_confirmation !== undefined && (
                  <ErrorMessage message={formError.password_confirmation} />
                )}
                <div className="flex items-center gap-2">
                  <input
                    id="privacy_statement"
                    type="checkbox"
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        privacy_statement: e.target.checked,
                      })
                    }
                  />
                  <span className="text-sm">
                    <Trans
                      i18nKey="general.privacy-policy-consent"
                      components={{
                        link1: (
                          <a
                            href={process.env.REACT_APP_PRIVACY_POLICY}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                            className="text-primary dark:text-primary-light"
                          />
                        ),
                      }}
                    />
                  </span>
                </div>

                {formError.privacy_statement !== undefined && <ErrorMessage message={formError.privacy_statement} />}

                <div className="flex w-full justify-between gap-x-4">
                  <button
                    type="button"
                    className="dark:glass flex h-[46px] w-full items-center justify-between gap-4 rounded-xl bg-white px-3 font-robotoslab font-bold text-primary shadow-md dark:text-primary-light"
                    onClick={() => setFormSection(formSection - 1)}
                  >
                    <FontAwesomeIcon icon="fa-regular fa-arrow-left-long" /> Vorige stap
                  </button>
                  <button
                    type="submit"
                    className="flex h-[46px] w-full items-center justify-between gap-4 rounded-xl bg-button-primary px-3 font-robotoslab font-bold shadow-md dark:bg-primary"
                  >
                    Volgende stap <FontAwesomeIcon icon="fa-regular fa-arrow-right-long" />
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
        <GuestNav />
        <CapacitorController />
      </div>
    </>
  );
};

export default Register;
