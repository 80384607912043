import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OneSignal from "react-onesignal";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import Lenis from "lenis";
import "lenis/dist/lenis.css";

import { ThemeProvider } from "./composable/ThemeContext";
import Home from "./views/Home";
import Discover from "./views/discover/Discover";
import DiscoverItem from "./views/discover/DiscoverItem";
import Shop from "./views/shop/Shop";
import OrderHistory from "./views/shop/OrderHistory";
import OrderDetail from "./views/shop/OrderDetail";
import ProductRegistration from "./views/product_registration/ProductRegistration";
import WarrantyRegistration from "./views/warranty_registration/WarrantyRegistration";
import Login from "./views/auth/Login";
import Profile from "./views/user/Profile";
import Register from "./views/auth/Register";
import AreaOfInterest from "./views/user/AreaOfInterest";
import Platform from "./views/platform/Platform";
import HistoryProductRegistration from "./views/product_registration/HistoryProductRegistration";
import DetailProductRegistration from "./views/product_registration/DetailProductRegistration";
import StartUp from "./views/WelcomeScreen";
import ForgotPassword from "./views/auth/ForgotPassword";
import ForgotPasswordSuccess from "./views/auth/ForgotPasswordSuccess";
import HistoryWarrantyRegistration from "./views/warranty_registration/HistoryWarrantyRegistration";
import DetailWarrantyRegistration from "./views/warranty_registration/DetailWarrantyRegistration";
import ErrorPage from "./views/ErrorPage";
import ProfileEdit from "./views/user/ProfileEdit";
import ChangePassword from "./views/user/ChangePassword";
import AddressEdit from "./views/user/AddressEdit";
import More from "./views/More";
import Notifications from "./views/Notifications";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthRoute } from "./components/AuthRoute";

function App() {
  library.add(fab, far, fas);

  const lenis = new Lenis();

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== "undefined") {
      if (!sessionStorage.getItem("oneSignalInit")) {
        OneSignal.init({
          appId: process.env.REACT_APP_ONE_SIGNAL_ID,
          // You can add other initialization options here
          notifyButton: {
            enable: true,
          },
          // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
          allowLocalhostAsSecureOrigin: true,
        }).then(() => {
          sessionStorage.setItem("oneSignalInit", true);
        });
      }
    }
  }, []);

  // Routing
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<StartUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/wachtwoord-vergeten" element={<ForgotPassword />} />
          <Route path="/login/wachtwoord-vergeten/success" element={<ForgotPasswordSuccess />} />
          <Route
            path="/profiel"
            element={
              <AuthRoute>
                <Profile />
              </AuthRoute>
            }
          />
          <Route path="/registreren" element={<Register />} />
          <Route
            path="/voorkeur"
            element={
              <AuthRoute>
                <AreaOfInterest />
              </AuthRoute>
            }
          />
          <Route
            path="/profiel/profielgegevens-aanpassen"
            element={
              <AuthRoute>
                <ProfileEdit />
              </AuthRoute>
            }
          />
          <Route
            path="/profiel/adress-aanpassen"
            element={
              <AuthRoute>
                <AddressEdit />
              </AuthRoute>
            }
          />
          <Route
            path="/profiel/wachtwoord-wijzigen"
            element={
              <AuthRoute>
                <ChangePassword />
              </AuthRoute>
            }
          />
          <Route
            path="/home"
            element={
              <AuthRoute>
                <Home />
              </AuthRoute>
            }
          />
          <Route
            path="/discover"
            element={
              <AuthRoute>
                <Discover />
              </AuthRoute>
            }
          />
          <Route
            path="/discover/item"
            element={
              <AuthRoute>
                <DiscoverItem />
              </AuthRoute>
            }
          />
          <Route path="/cadeauwinkel" element={<Shop />} />
          <Route
            path="/cadeauwinkel/bestellingen"
            element={
              <AuthRoute>
                <OrderHistory />
              </AuthRoute>
            }
          />
          <Route
            path="/cadeauwinkel/bestellingen/:id"
            element={
              <AuthRoute>
                <OrderDetail />
              </AuthRoute>
            }
          />
          <Route
            path="/actieregistratie"
            element={
              <AuthRoute>
                <ProductRegistration />
              </AuthRoute>
            }
          />
          <Route
            path="/actieregistratie/registraties"
            element={
              <AuthRoute>
                <HistoryProductRegistration />
              </AuthRoute>
            }
          />
          <Route
            path="/actieregistratie/registraties/:id"
            element={
              <AuthRoute>
                <DetailProductRegistration />
              </AuthRoute>
            }
          />
          <Route
            path="/garantieregistratie"
            element={
              <AuthRoute>
                <WarrantyRegistration />
              </AuthRoute>
            }
          />
          <Route
            path="/garantieregistratie/registraties"
            element={
              <AuthRoute>
                <HistoryWarrantyRegistration />
              </AuthRoute>
            }
          />
          <Route
            path="/garantieregistratie/registraties/:id"
            element={
              <AuthRoute>
                <DetailWarrantyRegistration />
              </AuthRoute>
            }
          />
          <Route path="/platform" element={<Platform />} />
          <Route path="/more" element={<More />} />
          <Route
            path="/notifications"
            element={
              <AuthRoute>
                <Notifications />
              </AuthRoute>
            }
          />

          {/* error page */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        stacked
        autoClose={5000}
        theme={"colored"}
        closeOnClick
        pauseOnHover
        position={"top-center"}
        transition={Zoom}
      />
      <div className="left-0 right-0 top-0 -z-10 hidden h-full -translate-y-[10%] bg-radial-gradient dark:fixed dark:block lg:bg-radial-gradient-lg">
        &nbps;
      </div>
    </ThemeProvider>
  );
}

export default App;
