import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

const StatusBar = (props) => {
  const { t } = useTranslation();

  return (
    <div className="relative z-10 flex flex-col gap-y-4">
      <div className={`relative flex w-full ${props.statuses.length > 1 ? "justify-between" : "justify-center"}`}>
        <div className="relative flex w-full items-center justify-between">
          <div className="absolute top-1/2 h-[2px] w-full -translate-y-1/2 transform bg-primary"></div>

          {props.statuses.toReversed().map((status) => (
            <div className="relative flex flex-col items-center" key={status.code}>
              <div
                className={`relative z-[1] flex size-10 items-center justify-center rounded-full ${
                  status.code === "999" || status.code === "500" ? "bg-makita-red dark:bg-makita-red" : "bg-primary"
                }`}
              >
                <FontAwesomeIcon
                  icon={
                    status.code === "999"
                      ? "fa-regular fa-xmark"
                      : status.code === "500"
                        ? "fa-regular fa-question"
                        : status.code === "100"
                          ? "fa-regular fa-clock"
                          : status.code === "400"
                            ? "fa-truck fa-regular"
                            : "fa-regular fa-check"
                  }
                  className="text-xl text-white"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {!props.hideText && (
        <div className="mb-5 flex justify-center">
          <span className="text-center">{t(`status.${props.statuses.toReversed()[0].name.toLowerCase()}`)}</span>
        </div>
      )}
    </div>
  );
};

export default StatusBar;
